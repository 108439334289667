.performanceChart {
    background-color: black;
    width: 258px;
    height: 264px;
    border-radius: 5px;
    grid-row: 2;
    grid-column: 2;
}
.recharts-polar-grid-angle line {
    display: none;
}