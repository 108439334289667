.activityChart {
    position: relative;
    background-color: #FBFBFB;
    width: 835px;
    height: 320px;
    grid-row: 1;
    grid-column: 1/4;
}
.activityChart h2 {
    position: absolute;
    margin-top: 1.5rem;
    margin-left: 2rem;
    font-size: 1rem;
    font-weight: 700;
}
.activityChart .recharts-default-legend {
    margin-top: 1.5rem!important;
    padding-bottom: 4rem!important;
}
.activityChart .recharts-legend-item-text, .recharts-cartesian-axis-ticks {
    color: #9B9EAC!important;
}
.activityChart .chart-tooltip {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2.5rem;
    height: 4rem;
    background-color: #E60000;
    font-size: 0.5rem;
    color: white;
    text-align: center;
}
.recharts-cartesian-axis-tick line {
    display: none;
}
.yAxis line {
    display: none;
}