.dashboard {
    margin-top: 4rem;
    margin-left: 6.5rem;
}
.charts {
    display: grid;
    grid-template-columns: repeat(4, 258px);
    grid-template-rows: 320px 263px;
    column-gap: 30px;
    row-gap: 28px;
}
.keyDataContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-row: 1/3;
}