.error-container {
    margin-top: 4rem;
    margin-inline: auto;
    text-align: center;
}
.error-container h1 {
    color: #E60000;
    font-size: 16rem;
}
.error-container p {
    font-size: 2.25rem;
}
.error-container a {
    font-size: 1rem;
    text-decoration: none;
    margin-top: 18rem;
}
.link {
    margin-top: 10rem;
}
.link:hover {
    transform: scale(1.1);
    text-decoration: underline;
}